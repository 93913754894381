import { render, staticRenderFns } from "./Cantanhede.vue?vue&type=template&id=49ce3bed&scoped=true&"
import script from "./Cantanhede.vue?vue&type=script&lang=js&"
export * from "./Cantanhede.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ce3bed",
  null
  
)

export default component.exports